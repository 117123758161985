import Tippy from "@tippyjs/react";
import IconPDF from "../icon/IconPDF";

function TableRowDrawing (props) {

    const { row } = props
    const { openPdf } = props

    return (
    <td>
        {row.pdfs.length > 0 ?
        <Tippy placement="right" theme="light" interactive={true} maxWidth="9999px" content={
            <div className="pdf-thumbnail">            
                <object id="pdf" type="application/pdf" data={"./pdf/" + row.pdfs[0]} className="w-100 h-100"></object>
            </div>
        }>
            <i className="fas fa-file-pdf text-danger cursor-pointer  fs-4 pdf-icon" onClick={() => {openPdf(row.pdfs[0])}}></i>   
        </Tippy>            
        :
        null
        }  
    </td>  
    );
}

export default TableRowDrawing;