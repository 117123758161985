import Icon from "./Icon"

const getOrigin = (link) => {
    try {
      return new URL(link).origin
    } catch (e) {
      return ""
    }
  }

function IconLink(props) {

    const { isShow } = props
    const { link } = props
    const { hideTooltip } = props
    const { extraClass } = props

    return (
        <a href={link} target="_blank"><Icon isShow={isShow} tooltip={hideTooltip ? undefined : getOrigin(link)} iconClassName={"fas fa-link " + extraClass}/></a>
    );  
}

export default IconLink;