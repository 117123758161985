import React from 'react';
import CloseButton from './ui/CloseButton';

function PdfView(props) {

    const { handleClose } = props
    const { pdf } = props

    return (
        pdf ?
        <main id="d-flex flex-column align-items-stretch" className="pdf-container bg-dark">
            <nav className="d-flex justify-content-center m-1">
                <CloseButton handleClose={handleClose} extraText="PDF"/>
            </nav>
            <div className="d-flex h-100">
                <object id="pdf" type="application/pdf" data={pdf} className="w-100 h-100"></object>        
            </div>            
        </main>
        : null
    );
}

export default PdfView;