import Tippy from '@tippyjs/react';
import { useState } from 'react';

function Filter(props) {

    const { handleFilter } = props
    const { filterProps } = props

    const [test, setTest] = useState(true)

    const handleChange = () => {
        let hidden = true
        for(let prop of filterProps) {
            if(prop.ref.current.checked) {
                hidden = false
                break                
            }
        }
        handleFilter()
        setTest(hidden)        
    }

    return (
    <div className="dropdown">
        <Tippy content="Filter">
            <button className="btn panel shadow m-1 btn-custom" type="button" id="dropDownFilter" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fas fa-filter"></i>
            <i className="fas fa-circle text-primary position-absolute" style={{bottom:"0", left:"0"}} hidden={test}></i>
            </button>
        </Tippy>  
        <ul className="dropdown-menu" aria-labelledby="dropDownFilter">
        {filterProps.map((prop, index) => {
            const id = "filter-checkbox" + index
            return(
                <li key={index} className="dropdown-item">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id={id} ref={prop.ref} onChange={handleChange}/>
                        <label className="form-check-label" htmlFor={id}>{prop.icon} {prop.text}</label>
                    </div>
                    {prop.line ? <hr className="mb-1"/> : null}
                </li>
            )
        })}
        </ul>        
    </div>  
    );
}

export default Filter;