import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import Table from './components/table/Table';
import PdfView from './components/PdfView';
import SearchBar from './components/SearchBar';
import Search from "./search.js"
import InfoView from './components/InfoView';
import DetailView from './components/DetailView';
import XLSX from "xlsx"
import Navbar from './components/Navbar';
import saveAs from "file-saver"
import 'tippy.js/themes/light.css';
import IconProvided from './components/icon/IconProvided';
import IconConsumable from './components/icon/IconConsumable';
import IconPDF from "./components/icon/IconPDF"
import IconManufactured from './components/icon/IconManufactured';
import IconAssembly from './components/icon/IconAssembly';

const navBarTitle = window.CONFIG.title
const showHomeButton = window.CONFIG.showHomeButton
const components = window.COMPONENTS
const columnNameMap = window.CONFIG.columnNameMap

const db = window.COMP_MAP

const skipParams = ["do_dokumentacie", "pocet_kusov", "oznacenie", "material", "polotovar"]

const removeFileNameExtension = (name) => {
  return name.substring(0, name.length - 4)
}

const resolveCompNum = (row) => {
  if(row.provided) {
    let ozn = row.params.oznacenie
    if(ozn == undefined || ozn == null || ozn === "") {
      // return row.params.objednavaci_kod
      return null
    } else return ozn
  }
  else return removeFileNameExtension(row.fileName)
}

//TODO presunut globalne premenne tu
function App() {  

  const [history, setHistory] = useState([])
  const [bom, setBom] = useState(components);
  const [filtered, setFiltered] = useState(components)
  const [info, setInfo] = useState(null);  
  const [loading, setLoading] = useState(false)
  const [detailComp, setDetailComp] = useState(null)
  const [pdf, setPdf] = useState(null)
  const inputRef = useRef(null) 

  const openPdf = (doc) => {
    const path = "./pdf/" + doc
    setPdf(path)
  }

  const openInfo = (row) => {
    setDetailComp(row)
  }

  const closeDetailView = () => {
    setDetailComp(null)
  }

  const handlePdfClose = () => {
    setPdf(null)
  }

  const handleInfoClose = () => {
    const pdfContainer = document.getElementById("info-container")
    if(pdfContainer) {
      pdfContainer.style.display = "none"
    }
  }

  const handleSearch= () => {
    const input = inputRef.current?.value;   
    if(input === undefined || input === "") setBom(filtered)
    const search = new Search(filtered) //TODO dat to hore aby to bralo vzdy 
    search.field("fileName") //TODO fieldy dat na zaciatok a brat aj pole napr z configu
    search.field("type")
    search.field("params.nazov")
    search.field("params.dodavatel")
    search.field("params.vypracoval")
    search.field("params.oznacenie")
    search.field("params.objednavaci_kod")    
    search.field("params.projekt")
    setBom(search.searchCascade(input))
    setLoading(false)
  }

  const handleSub = (fileName) => {
    components.map(row => {
      if(row.fileName === fileName) {
        setHistory(history.concat(row.fileName))
        setInfo(row)
      }
    })
  }

  const formatBoolean = (provided) => {
    return provided ? "áno" : ""
  }

  const formatType = comp => {
    const provided = comp.provided
    const isAsm = comp.type === "asm"
    if(provided) return "nakupovaný"
    if(!provided && isAsm) return "zostava"
    if(!isAsm && !provided) return "vyrábaný"
    return ""
  }

  const formatParents = parents => {
    let result = ""
    for(let parent of parents) {
      result += db[parent.idName].params.nazov + " - " + parent.count + ", "
    }
    return result
  }

  const resolveColumnName = (name) => {
    return columnNameMap.has(name) ? columnNameMap.get(name) : name
  }

  const createBomAoA = () => {
    const paramSet = new Set()
    const skipParams = ["projekt", "do_dokumentacie", "hmotnost", "nazov", "oznacenie", "dodavatel", "pocet_kusov", "polotovar", "objednavaci_kod", "klasifikacia"]
    for(let row of bom) {
      const keys = Object.keys(row.params)
      for(let key of keys) {
        if(!skipParams.includes(key)) {
          paramSet.add(key)
        }        
      }      
    }
    const params = Array.from(paramSet)
    const extraColumns = []
    for(let param of params) {
      extraColumns.push(resolveColumnName(param))
    }    
    const data = [
      ["#","Typ","Opotrebiteľný","Názov","Počet kusov","Označenie","Obj. kód","Dodávateľ"].concat(extraColumns)
    ]
    let i = 1
    for(let row of bom) {
      const paramVals = []
      for(let param of params) {
        const val = row.params[param]
        if(val !== undefined || val !== null) {
          paramVals.push(val)
        } else {
          paramVals.push("")
        }
      }
      const fixedFields = [
        i,
        formatType(row), 
        row.params.klasifikacia,
        row.params.nazov,
        row.count, 
        resolveCompNum(row), 
        row.params.objednavaci_kod,                
        row.params.dodavatel,                 
      ]
      data.push(fixedFields.concat(paramVals))
      i++
    }
    return data
  }

  const exportXLSX = () => {
    const data = createBomAoA()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)
    XLSX.utils.book_append_sheet(wb, ws, "DOKUMENTACIA")
    XLSX.writeFile(wb, "dokumentacia.xlsx")
  }

  const exportCSV = () => {
    const data = createBomAoA()
    let csvContent = "";
    data.forEach(function(rowArray) {
        let row = rowArray.join(";");
        csvContent += row + "\r\n";
    });

    const blob = new Blob([csvContent], {type: "data:text/csv;charset=utf-8"});
    saveAs(blob, "dokumentacia.csv")
  }

  const exportJSON = () => {
    const blob = new Blob([JSON.stringify(window.BOM, null, 2), {type: "data:text/json;charset=utf-8"}])
    saveAs(blob, "dokumentacia.json")
  }  

  const filterProps = [
    {
      ref: useRef(null),
      filterFn: row => {return !row.provided && row.type !== "asm"},
      text: "Vyrábaný",
      icon: <IconManufactured isShow={true} hideTooltip={true}/>,
      group: "pma"
    }, 
    {
      ref: useRef(null),
      filterFn: row => {return row.provided},
      text: "Nakupovaný",
      icon: <IconProvided isShow={true} hideTooltip={true}/>,
      group: "pma"
    },
    {
      ref: useRef(null),
      filterFn: row => {return !row.provided && row.type === "asm"},
      text: "Zostava",
      icon: <IconAssembly isShow={true} hideTooltip={true}/>,
      group: "pma",
      line: true
    },
    {
      ref: useRef(null),
      filterFn: row => {return row.consumable && row.params.klasifikacia === "A"},
      text: "Opotrebiteľný",
      icon: <IconConsumable isShow={true} hideTooltip={true} severity={"A"}/>,
      group: "consumable"
    },
    {
      ref: useRef(null),
      filterFn: row => {return row.consumable && row.params.klasifikacia === "B"},
      text: "Opotrebiteľný",
      icon: <IconConsumable isShow={true} hideTooltip={true} severity={"B"}/>,
      group: "consumable"
    },
    {
      ref: useRef(null),
      filterFn: row => {return row.consumable && row.params.klasifikacia === "C"},
      text: "Opotrebiteľný",
      icon: <IconConsumable isShow={true} hideTooltip={true} severity={"C"}/>,
      group: "consumable",
      line: true
    },
    {
      ref: useRef(null),
      filterFn: row => {return row.pdfs.length > 0},
      text: "Výkres",
      icon: <IconPDF isShow={true} hideTooltip={true}/>
    },         
  ]

  const handleFilter = () => {
    inputRef.current.value = ""
    let nbom = components
    const filters = [] 
    const groupFilters = {}
    for(let prop of filterProps) {
      const checked = prop.ref.current.checked
      const fn = prop.filterFn
      const groupName = prop.group
      if(checked && fn !=+ undefined) {
        if(groupName !== undefined) {
          const filterGroup = groupFilters[groupName]
          if(filterGroup != undefined) {
            filterGroup.push(fn)
          } else {
            groupFilters[groupName] = [fn]
          }
        } else {
          filters.push(fn)
        }        
      }
    }

    for(let key of Object.keys(groupFilters)) {
      const filterGroup = groupFilters[key]
      filters.push(row => {
        for(let gFilter of filterGroup) {
          if(gFilter(row)) return true
        }
        return false
      })
    }

    if(filters.length > 0) {
      nbom = nbom.filter(row => {
        let hit = 0
        for(let fn of filters) {
          if(fn(row)) hit++
        }
        return hit === filters.length
      })     
    }
    setFiltered(nbom)
    setBom(nbom)
  }  

  const resolveImageLink = (link) => {
    try {
      new URL(link)
      return link
    } catch(e) {
      return "./img/" + link
    }
  }

  const resolveProvidedIcon = (row) => {
    const provided = row.provided
    const manufactured = !provided && row.type !== "asm"
    if(provided) {
      return <IconProvided isShow={true}/>
    } else if(manufactured) {
      return <IconManufactured isShow={true}/>
    } else {
      return <IconAssembly isShow={true}/>
    }
  }

  const handleForward = () => {
    if(detailComp) {
      const compLen = bom.length
      for(let i = 0; i < compLen; i++) {
        const comp = bom[i]
        if(comp === detailComp && i < compLen - 1){
          setDetailComp(bom[i + 1])
        }
      }
    }    
  }

  const handleBack = () => {
    if(detailComp) {
      const compLen = bom.length
      for(let i = 0; i < compLen; i++) {
        const comp = bom[i]
        if(comp === detailComp && i > 0){
          setDetailComp(bom[i - 1])
        }
      }
    }    
  }

  const onKeyDown = e => {
    if(e.key === "Escape") {
      inputRef.current.blur()
      if(pdf) {
        setPdf(null)
      } else if(detailComp) {
        setDetailComp(null)
      }
    } else if(e.key === "ArrowRight") {
      if(detailComp) handleForward()
    } else if(e.key === "ArrowLeft") {
      if(detailComp) handleBack()
    } else if(e.key === "k" && e.ctrlKey) {
      e.preventDefault()
      inputRef.current.focus()
    }
  }

  useEffect(() => {    
    window.addEventListener("keydown", onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    }
  }, [pdf, detailComp]) 

  return (
    <div className="App conatainer-fluid">     
      <header>
        <Navbar 
          exportXLSX={exportXLSX}
          exportCSV={exportCSV}
          exportJSON={exportJSON}
          openElectricScheme={() => {openPdf(window.CONFIG.electricalSchemePdf)}}  
          handleFilter={handleFilter}
          title={navBarTitle}
          showHomeButton={showHomeButton}
          filterProps={filterProps}
          openManual={() => openPdf(window.CONFIG.manualPdf)}
        />
      </header>  

      <main className="container-fluid d-flex flex-column mm">
        {loading ? 
        <div className="loading">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div> : null}
        <DetailView 
          comp={detailComp}
          close={closeDetailView}
          resolveImageLink={resolveImageLink}
          openPdf={openPdf}
          openRelate={openInfo}
          compMap={db}
          resolveCompNum={resolveCompNum}
          resolveProvidedIcon={resolveProvidedIcon}
          skipParams={skipParams}
          paramNameMap={columnNameMap}
          handleForward={handleForward}
          handleBack={handleBack}
        />
        <PdfView handleClose={handlePdfClose} pdf={pdf}/>
        <InfoView handleClose={handleInfoClose} row={info} handleSub={handleSub} handleBack={handleBack}/>
        <SearchBar handleInput={handleSearch} inputRef={inputRef}/>
        <Table 
          bom={bom}
          openPdf={openPdf}
          openInfo={openInfo} 
          db={db} 
          resolveImageLink={resolveImageLink}
          resolveCompNum={resolveCompNum} 
          resolveProvidedIcon={resolveProvidedIcon}
          skipParams={skipParams}
          paramNameMap={columnNameMap}
          // sortFunction={(a, b) => a.params.nazov > b.params.nazov ? 1 : -1}
          />        
      </main>
    </div>
  );
}

export default App;
