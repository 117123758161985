import Icon from "./Icon"

function IconAssembly(props) {

    const { isShow } = props
    const { hideTooltip } = props

    return (
        <Icon isShow={isShow} tooltip={hideTooltip ? undefined : "zostava"} iconClassName="fas fa-cubes text-primary"/>
    );  
}

export default IconAssembly;