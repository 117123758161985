import React from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import Icon from "./icon/Icon"
import Tippy from "@tippyjs/react"

function InfoView(props) {

    const { handleClose } = props
    const { row } = props
    const { handleSub } = props
    const { handleBack } = props
    const { handleForward } = props

    const imgZoomScale = window.CONFIG.imgZoomScale ? window.CONFIG.imgZoomScale : 1

    const editModelName = (name) => {
        let newName = name.substring(0, name.length - 4)
        if(newName.includes("<")) return newName.substring(0, newName.indexOf("<"))
        return newName
    }

    const resolveNum = (row) => {
        if(row != null) {
            if(row.provided){
                if(row.params.oznacenie === "") {
                    let obj_kod = row.params.objednavaci_kod
                    if(obj_kod === "") return row.idName
                    else return obj_kod
                }
                else return row.params.oznacenie
            } 
            else return editModelName(row.idName)
        } 
    }

    const resolveClassif = (row) => {
        if(row != null) {
            if(row.params.klasifikacia == undefined) return "opotrebiteľný";
            else return "opotrebiteľný -> " + row.params.klasifikacia
        }        
    }

    const resolveClassifProvided = (row) => {
        if(row != null) {
            if(row.params.provided != undefined) return "nakupovany";
        }        
    }

    const ipo = (path) => {
        let n1 = path.substring(0, path.indexOf("<"))
        let n2 = path.substring(path.indexOf(">") + 1)
        return n1 + n2
    }

    const getModelImage = (idName) => {
        for(let model of window.COMPONENTS) {
            if(model.idName === idName) {                
                let path = model.images[0]
                if(path === undefined || path === null) return "default.webp"
                else if(path.includes("<")) return ipo(path)
                return path
            } 
        }
    }
    
    const getModel = (idName) => {
        for(let model of window.COMPONENTS) {
            if(model.idName === idName) {
                return model
            } 
        }
        return null
    }

    const resolveType = (strType) => {
        if(strType === "prt") return (
          <div className="asm-icon">
            <i className="fas fa-cog text-teal"></i>
          </div>
        ) 
        else if(strType === "asm") return (
          <div className="asm-icon">
            <i className="fas fa-cog text-yellow"></i>
            <div className="asm-sub-icon">
              <i className="fas fa-cog text-teal"></i>
              <i className="fas fa-cog text-teal"></i>
            </div>
          </div>
        )
        else return <i className="fas fa-question"></i>
    }

    const getConsIconClassName = (row) => {
        let klas = row?.params.klasifikacia
        if(klas) {
          if(klas === "A") {
            return "text-success"
          } else if(klas === "B") {
            return "text-warning"
          } else {
            return "text-danger"
          }
        } else {
          return "text-primary"
        }
      }

    return (
    <div id="info-container" className="info">
        <p className="position-absolute m-1 text-light">{row?.idName}</p>
        <div className="container-fluid w-75">
            <div className="batngrup mb-1">
                <button className="btn btn-info btn-sm" type="button" onClick={handleBack}>
                    <i className="fas fa-arrow-left"></i>
                </button>
                <button className="btn btn-danger btn-sm" type="button" onClick={handleClose}>
                    <i className="fas fa-times-circle"></i>
                </button>
                <button className="btn btn-info btn-sm" type="button" onClick={handleBack}>
                    <i className="fas fa-arrow-right"></i>
                </button>
            </div>
            {/* <img src={"img/" + row?.images[0]} width="50%" className="rounded mt-5 shadow" id="image-container"/> */}
            <div className="info-data">                
                <div className="text-light ti">
                    <h2>Info:</h2>
                    {row?.root ? <h5 className="text-warning">HLAVNÁ ZOSTAVA</h5> : <p></p>}
                    <div className="text-start p-3">                    
                        <p><span className="underl">ID: </span> {resolveNum(row)}</p>
                        {
                            row?.params != null ?
                            Object.keys(row.params).map((key, index) => {
                                let val = row.params[key]
                                if(val !== "" && key !== "pocet_kusov" && key !== "do_dokumentacie" && key !== "zmena") {
                                    if(key === "hmotnost") val = val.toFixed(3)
                                    return <p key={index}><span className="underl">{key.toUpperCase()}</span>: {val}</p>
                                }
                            }) : null
                        }
                        <p><span className="underl">POCET KUSOV: </span><span>{row?.count}</span></p>
                        <div className="flexo">
                            {/* <p className="mt-1">{resolveType(row?.type)}</p> */}
                            <p width="16px" className="pl-1"><Icon isShow={row?.consumable} iconClassName={"fas fa-exclamation-triangle " + getConsIconClassName(row)} tooltip="opotrebiteľný"/></p>
                            <p width="16px" className="pl-1"><Icon isShow={row?.provided} iconClassName="fas fa-shopping-basket text-info" tooltip="nakupovaný"/></p>
                        </div>                    
                    </div>        
                </div>
                <div>
                    <InnerImageZoom 
                        alt="test"
                        zoomScale={imgZoomScale}
                        src={"img/" + row?.images[0]}
                        zoomSrc={"img/" + row?.images[0]}
                        className="ti rounded shadow"
                    />
                </div>
            </div>
            <div className="info-data text-light">
                <div className="p-2 w-100">                    
                    <h2>Obahuje:</h2>
                    <div className="text-center p-3 overflow-auto">
                        {row?.children.map((row, index) => {
                            return <p key={index}><Tippy arrow={false} content={<img src={"img/" + getModelImage(row.idName)} width="300"/>}><a key={index} className="text-light" onClick={() => handleSub(row.idName)} href="#"><span className="underl">{getModel(row.idName).params.nazov}</span>: {resolveNum(getModel(row.idName))} - {row.count}ks</a></Tippy></p>
                        })} 
                    </div>                                       
                </div>
                <div className="p-2 w-100 border-left">
                    <h2>Umiestnenie:</h2>
                    <div className="text-center p-3">
                        {row?.parents.map((row, index) => {
                                return <p key={index}><Tippy content={<img src={"img/" + getModelImage(row.idName)} width="300"/>}><a key={index} className="text-light" onClick={() => handleSub(row.idName)} href="#"><span className="underl">{getModel(row.idName).params.nazov}</span>: {resolveNum(getModel(row.idName))} - {row.count}ks</a></Tippy></p>
                        })}
                    </div>                    
                </div>
            </div>        
        </div>      
    </div>
    );
}

export default InfoView;