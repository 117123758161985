import Tippy from "@tippyjs/react"

function Icon(props) {

    const { iconClassName } = props
    const { isShow } = props
    const { tooltip } = props
    const { onClick } = props

    if(isShow) {
        return (
            tooltip
            ?
            <Tippy content={tooltip} theme="light">
                <i className={iconClassName + " fs-4"} onClick={onClick}></i>
            </Tippy>            
            :
            <i className={iconClassName + " fs-4"} onClick={onClick}></i>                
        );        
    } else {
        return null
    }    
}

export default Icon;