import Icon from "./Icon"

function IconManufactured(props) {

    const { isShow } = props
    const { hideTooltip } = props

    return (
        <Icon isShow={isShow} tooltip={hideTooltip ? undefined : "vyrábaný"} iconClassName="fas fa-industry text-success"/>
    );  
}

export default IconManufactured;