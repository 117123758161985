import Icon from "./Icon"

function IconPDF(props) {

    const { isShow } = props
    const { hideTooltip } = props
    const { onClick } = props
    const { extraClass } = props

    return (
        <Icon onClick={onClick} isShow={isShow} tooltip={hideTooltip ? undefined : "výkres"} iconClassName={"fas fa-file-pdf text-danger cursor-pointer " + extraClass}/>
    );  
}

export default IconPDF;