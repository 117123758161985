import React from 'react';

function SearchBar(props) {

    const { handleInput } = props
    const { inputRef } = props

    return (
        <div className="mt-2 search">
            <form className="form-group" onSubmit={(e)=>{e.preventDefault()}}>
                <input 
                type="text" 
                placeholder="vyhľadať" 
                className="form-control w-100 text-center xyz"
                ref={inputRef}
                onChange={handleInput}
                autoFocus
                />
            </form>
        </div>
        
    );
}

export default SearchBar;