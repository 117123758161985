import Tippy from "@tippyjs/react"

function UsagePreview(props) {

const { row } = props
const { db } = props
const { openInfo } = props

const resolveImageLink = (link) => {
  try {
    new URL(link)
    return link
  } catch(e) {
    return "./img/" + link
  }
}

const findCountInParents = (idName, parents) => {
  let count = 0
  for(let parent of parents) {
    if(parent.idName === idName) count = parent.count
  }
  return count;
}

    return (
        <ul className="px-0 mx-0 list-group">
            {row.parents.map((parent, index) => {
              const parentComp = db[parent.idName]
              return (
                <Tippy 
                  key={index}
                  theme="light"
                  maxWidth="1000px"
                  content={
                    <section className="position-relative">
                      <header className="position-absolute text-start p-1">
                        <h4><span className="badge rounded-pill bg-primary">{findCountInParents(parent.idName, row.parents)}ks</span></h4>
                      </header>                      
                      <img width="512" src={resolveImageLink(parentComp.images[0])}/>
                    </section>                    
                  }>
                  <li className="px-0 link" onClick={() => {
                    openInfo(parentComp)}} key={index}>{parentComp.params.nazov}</li>
                </Tippy>
              )
            })}
        </ul>
    );
}

export default UsagePreview;