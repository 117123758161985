import Tippy from '@tippyjs/react';
import React from 'react';
import logo from "../logo.png"
import About from './About';
import Export from './Export';
import Filter from './Filter';
import Legend from './Legend';

function Navbar(props) {

    const { exportXLSX } = props
    const { exportCSV } = props
    const { exportJSON } = props
    const { openElectricScheme } = props
    const { handleFilter } = props
    const { title } = props
    const { showHomeButton } = props
    const { openManual } = props
    
    const { filterProps } = props

    return (
        <nav className="container-fluid panel text-dark">
            <div className="row">
                <div className="col-md text-md-start my-2">
                    <a href="http://avsys.sk" target="_blank">
                        <img src={logo} alt="logo" className="App-logo p-2 shadow rounded"/>                
                    </a>                    
                </div>
                <div className="col-md-auto d-flex align-items-center justify-content-center">
                    <h2>{title}</h2>
                </div>
                <div className="col-md d-flex align-items-center justify-content-md-end justify-content-center">    
                    {showHomeButton ? <Tippy content="Hlavná stránka"><a href="https://youtube.com"><button type="button" className="btn panel shadow m-1 btn-custom"><i className="fas fa-home"></i></button></a></Tippy> : null}
                    <Filter handleFilter={handleFilter} filterProps={filterProps}/>
                    <Export exportXLSX={exportXLSX} exportCSV={exportCSV} exportJSON={exportJSON}/>
                    <Tippy content="Elektro / Pneumatická dokumentácia"><button type="button" className="btn panel shadow m-1 btn-custom" onClick={openElectricScheme}><i className="fas fa-bolt"></i></button></Tippy>
                    <Tippy content="Návod na použitie"><button type="button" className="btn panel shadow m-1 btn-custom" onClick={openManual}><i className="fas fa-book"></i></button></Tippy>
                    <Tippy content="O aplikácii">
                        <Tippy content={<About/>} theme="light" trigger="click">
                            <button type="button" className="btn panel shadow m-1 btn-custom"><i className="fas fa-info"></i></button>
                        </Tippy>                        
                    </Tippy>
                    <Tippy content="Legenda">
                        <Tippy content={<Legend/>} theme="light" trigger="click">
                            <button type="button" className="btn panel shadow m-1 btn-custom"><i className="fas fa-question"></i></button>
                        </Tippy>                        
                    </Tippy>                    
                </div>   
            </div>                     
        </nav>
    );
}

export default Navbar;