import { remove as normalize } from "diacritics"

class Search {

    fields = []
    data

    constructor(data) {
        this.data = data
    }

    field(name) {
        let arr = name.split(".")
        this.fields.push(arr)
    }

    pop(item, field) {
        let value = item
        let i = 0
        while(Array.isArray(value) || typeof value === "object") {
            value = value[field[i]]
            i++
        }
        return value
    }

    search(input) {
        if(input === "") return this.data
        let result = []
        for(let item of this.data) {
            const modified = normalize(input).toLowerCase()
            for(let i = 0; i < this.fields.length; i++) {
                const field = this.fields[i]
                let value = this.pop(item, field)
                if(!(value === undefined || value === null)) {
                    if(value.toLowerCase().includes(modified)) {    
                        result.push(item)
                        break
                    }
                }                
            }  
        }        
        return result
    }

    searchCascade(input) {
        if(input.length <= 1) return this.data
        const defData = this.data
        const tokens = input.split(" ")
        let result
        for(let token of tokens) {
            if(token === "") continue
            result = this.search(token)
            this.data = result
        }
        this.data = defData
        return result
    }
}

export default Search