import React from 'react';

function Legend(props) {

    const { handleClose } = props

    return (
    <div>
        <h4 className="text-center">LEGENDA</h4>
        <p><span>Prechodka</span><span className="text-info"> - Diel dokumentacie</span></p>     
        <p><span className="text-warning">Autom.valcovacky L300</span><span className="text-info"> - Hlavana zostava</span></p>     
        <div><i className="fas fa-exclamation-triangle text-secondary"></i><span className="text-info"> - Diel s nizkou mierou opotrebiteľnosti (klasifikácia C)</span></div>
        <div><i className="fas fa-exclamation-triangle text-warning"></i><span className="text-info"> - Diel so strednou mierou opotrebiteľnosti (klasifikácia B)</span></div>
        <div><i className="fas fa-exclamation-triangle text-danger"></i><span className="text-info"> - Diel s vysokou mierou opotrebiteľnosti (klasifikácia A)</span></div>
        <p className="my-3"><i className="fas fa-shopping-basket text-info"></i><span className="text-info"> - Nakupovaný diel</span></p>
        <p><i className="fas fa-info-circle text-info"></i><span className="text-info"> - Zobraziť podrobnosti dielu</span></p>                                                
        <p><i className="fas fa-link text-primary"></i><span className="text-info"> - Externý odkaz na internete</span></p>
        <p><i className="fas fa-file-pdf pdf-icon"></i><span className="text-info"> {'\u00A0'}- Výkres dielu</span></p>        
    </div>
    );
}

export default Legend;