import React from 'react';
import Tippy from '@tippyjs/react';

function DetailRelateItem(props) {

    const { comp } = props
    const { resolveCompNum } = props
    const { resolveImageLink } = props
    const { openRelate } = props
    const { count } = props
    
    const image = comp.images[0]

    return (
        <Tippy theme="light" maxWidth="1000px" arrow={false} content={<img className="relate-image" src={resolveImageLink(image)}/>}>
            <li 
                className="link" 
                onClick={() => {openRelate(comp)}}>
                    <span className="text-info">{comp.params.nazov}</span> {resolveCompNum(comp)} - {count}ks</li>
        </Tippy>
    );
}

export default DetailRelateItem;