import Tippy from '@tippyjs/react';
import React from 'react';

function Export(props) {

    const { exportJSON }  = props
    const { exportCSV } = props
    const { exportXLSX } = props

    return (
    <div className="dropdown">
      <Tippy content="Export">
        <button className="btn panel shadow m-1 btn-custom" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="fas fa-download"></i>
        </button>
      </Tippy>  
        <div className="dropdown-menu text-center" aria-labelledby="dropdownMenuButton">
          <Tippy placement="left" content="Zápis Javascript objektu"><button className="dropdown-item" onClick={exportJSON}>JSON</button></Tippy>
          <Tippy placement="left" content="Hodnoty oddelené čiarkou"><button className="dropdown-item" onClick={exportCSV}>CSV</button></Tippy>
          <Tippy placement="left" content="Excel"><button className="dropdown-item" onClick={exportXLSX}>XLSX</button></Tippy>
        </div>
    </div>  
    );
}

export default Export;