import React from 'react';

function CloseButton(props) {

    const { handleClose } = props
    const { extraText } = props
    const { className } = props

    return (
        <button className={"btn btn-danger btn-sm " + className} type="button" onClick={handleClose}>
            <i className="fas fa-times-circle"></i> Zatvoriť {extraText}
        </button>
    );
}

export default CloseButton;