import CloseButton from "./ui/CloseButton"
import InnerImageZoom from "react-inner-image-zoom"
import DetailRelateItem from "./detail/DetailRelateItem"
import IconConsumable from "./icon/IconConsumable"
import IconPDF from "./icon/IconPDF"
import IconLink from "./icon/IconLink"
import IconRoot from "./icon/IconRoot"

function DetailView(props) {

    const { comp } = props
    const { close } = props
    const { openPdf } = props
    const { resolveImageLink } = props
    const { openRelate } = props
    const { compMap } = props
    const { resolveCompNum } = props
    const { resolveProvidedIcon } = props
    const { skipParams } = props
    const { paramNameMap } = props
    const { handleForward } = props
    const { handleBack } = props

    const getComp = idName => {
        return compMap[idName]
    }

    const factoryRelateItem = (comp, count, index) => {
        return <DetailRelateItem 
            comp={comp}
            count={count}
            openRelate={openRelate}
            key={index}
            resolveCompNum={resolveCompNum}
            resolveImageLink={resolveImageLink}
        />
    }

    const formatVal = (key, val) => {
        let result
        switch(key) {
            case "hmotnost": 
                result = typeof val === "number" ? val.toFixed(3) : val
                break
            default: 
                result = val
                break
        }
        return typeof result === "string" ? result.trim() : result
    }

    const formatKey = key => {
        const name = paramNameMap.get(key)
        return name ? name : key.toUpperCase()
    }

    return (    
        comp ?    
        <main className="detail-view panel position-fixed d-flex flex-column text-light overflow-auto">
            <small className="position-absolute m-2 text-muted"><span>ID: </span>{comp.idName}</small>
            <nav className="mt-2">
                <button className="btn btn-info btn-sm me-2" onClick={() => {handleBack()}}><i className="fas fa-arrow-left"></i></button>
                <CloseButton handleClose={close} extraText="detail"/>
                <button className="btn btn-info btn-sm ms-2" onClick={() => {handleForward()}}><i className="fas fa-arrow-right"></i></button>
            </nav>            
            <article className="d-flex flex-row justify-content-center">
                <section className="w-50 mt-2 ms-3 me-0">
                    <div>
                        <InnerImageZoom src={resolveImageLink(comp.images[0])} className="w-100 rounded"/>
                        {comp.images.length > 1 ? 
                        comp.images.map((link, index) => {
                            return index > 0 ? <img width="400" className="me-2 rounded" key={index} src={resolveImageLink(link)}/> : null
                        })
                        : null}
                    </div>                
                </section>
                <div className="d-flex flex-column w-50 m-0">
                    <section>
                        <h2>Info:</h2>
                        <ul className="text-start detail-list">
                            {comp.root ? <li><IconRoot /></li> : null}
                            <li><span className="text-info">Označenie: </span>{resolveCompNum(comp)}</li>
                            <li><span className="text-info">Počet kusov: </span>{comp.count}</li>
                            {Object.keys(comp.params).map((key, index) => {
                                if(skipParams && !skipParams.includes(key)) {
                                    const val = comp.params[key]
                                    const formatedVal = formatVal(key, val)
                                    if(formatedVal === "") {
                                        return null
                                    } else {
                                        const formatedKey = formatKey(key)
                                        return <li key={index}><span className="text-info">{formatedKey}: </span>{formatedVal}</li>
                                    }                                    
                                } else {
                                    return null
                                }                                
                            })}                            
                            <li>
                                <span className="m-1 fs-2">{resolveProvidedIcon(comp)}</span>
                                <span className="m-1 fs-2"><IconConsumable isShow={comp.consumable} severity={comp.params.klasifikacia}/></span>
                            </li>
                            <li>
                                {comp.links.map((link, index) => {
                                    return <span className="m-1" key={index}><IconLink link={link} isShow={true} /></span>
                                })}
                                {comp.pdfs.map((pdf, index) => {
                                    return <span className="m-1" key={index}><IconPDF isShow={true} onClick={() => {openPdf(pdf)}} extraClass="pdf-icon"/></span>
                                })} 
                            </li>                                                       
                        </ul>
                    </section>
                    <div className="d-flex flex-row justify-content-around">
                        <section>
                            <h2>Obsahuje:</h2>
                            <ul className="text-start detail-list">
                                {comp.children.map((child, index) => {
                                    const childComp = getComp(child.idName)
                                    return factoryRelateItem(childComp, child.count, index)
                                })}
                            </ul>
                        </section>
                        <section>
                            <h2>Umiestnenie:</h2>
                            <ul className="text-start detail-list">
                                {comp.parents.map((parent, index) => {
                                    const parentComp = getComp(parent.idName)
                                    return factoryRelateItem(parentComp, parent.count, index)
                                })}
                            </ul>
                        </section>
                    </div>   
                </div>                             
            </article>
        </main>
        : null
    );
}

export default DetailView;