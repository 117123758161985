import { useState } from "react"
import Icon from "../icon/Icon"
import Tippy from "@tippyjs/react"
import UsagePreview from '../UsagePreview';
import IconProvided from '../icon/IconProvided';
import IconConsumable from '../icon/IconConsumable';
import IconExport3D from '../icon/IconExport3D';
import IconPDF from "../icon/IconPDF";
import TableRowName from "./TableRowName";
import TableRowImage from "./TableRowImage";
import IconSparePart from "../icon/IconSparePart";
import IconManufactured from "../icon/IconManufactured";
import IconAssembly from "../icon/IconAssembly";
import TableRowDrawing from "./TableRowDrawing";

const isSparePart = (comp) => {
  return !(comp.type === "asm" && !comp.provided)
}

function Table(props) {

  const { bom } = props
  const { db } = props
  const { openPdf } = props
  const { openInfo } = props
  const { resolveCompNum } = props  
  const { resolveImageLink } = props
  const { resolveProvidedIcon } = props
  const { skipParams } = props
  const { paramNameMap } = props

  const safeString = str => {
    let result 
    if(!(str === undefined || str === null)) {
      result = str
    } else {
      result = ""
    }
    return result.toLowerCase()
  }

  const sortIndexProvManuf = row => {
    const provided = row.provided
    const manufactured = !provided && row.type !== "asm"
    if(provided) {
      return 2
    } else if(manufactured) {
      return 1
    } else {
      return -1
    }
  }

  const sortIndexClassification = row => {
    const classification = row.params.klasifikacia
    if(classification === "A") {
      return 3
    } else if(classification === "B") {
      return 2
    } else if(classification === "C") {
      return 1
    } else {
      return -1
    }
  }

  const sortTypes = {
    name: {
     asc: (a, b)  => a.params.nazov > b.params.nazov ? 1 : -1,
     des: (a, b)  => a.params.nazov > b.params.nazov ? -1 : 1,
    },
    count: {
      asc: (a, b) => a.count - b.count,
      des: (a, b) => b.count - a.count,
    },
    num: {
      asc: (a, b) => safeString(resolveCompNum(a)) > safeString(resolveCompNum(b)) ? 1 : -1,
      des: (a, b) => safeString(resolveCompNum(b)) > safeString(resolveCompNum(a)) ? 1 : -1,
    },
    orderNum: {
      asc: (a, b) => safeString(a.params.objednavaci_kod) > safeString(b.params.objednavaci_kod) ? 1 : -1,
      des: (a, b) => safeString(b.params.objednavaci_kod) > safeString(a.params.objednavaci_kod) ? 1 : -1,
    },
    supplier: {
      asc: (a, b) => safeString(a.params.dodavatel) > safeString(b.params.dodavatel) ? 1 : -1,
      des: (a, b) => safeString(b.params.dodavatel) > safeString(a.params.dodavatel) ? 1 : -1
    },
    provided: {
      asc: (a, b) => sortIndexProvManuf(a) - sortIndexProvManuf(b),
      des: (a, b) => sortIndexProvManuf(b) - sortIndexProvManuf(a)
    },
    sparePart: {
      asc: (a, b) => isSparePart(a) - isSparePart(b),
      des: (a, b) => isSparePart(b) - isSparePart(a)
    },
    consumable: {
      asc: (a, b) => {
        return sortIndexClassification(a) - sortIndexClassification(b)
      },
      des: (a, b) => {
        // return safeString(b.params.klasifikacia) > safeString(a.params.klasifikacia) ? -1 : 1
        return sortIndexClassification(b) - sortIndexClassification(a)
      }
    },
    drw: {
      asc: (a, b) => {
        return a.pdfs.length - b.pdfs.length
      },
      des: (a, b) => {
        return b.pdfs.length - a.pdfs.length
      }
    },
    link: {
      asc: (a, b) => {
        return a.links.length - b.links.length
      },
      des: (a, b) => {
        return b.links.length - a.links.length
      }
    },
    export3d: {
      asc: (a, b) => {
        return a.export3d - b.export3d
      },
      des: (a, b) => {
        return b.export3d - a.export3d
      }
    },
  }

  const [sortConfig, setSortConfig] = useState({fn: undefined, direction: "asc", type: ""}) 

  const requestSort = sortType => {    
    const direction = sortConfig.direction
    const fn = sortTypes[sortType][direction]
    const newDirection = direction === "asc" ? "des" : "asc"
    setSortConfig({fn: fn, direction: newDirection, type: sortType})
  }

  const getOrigin = (link) => {
    try {
      return new URL(link).origin
    } catch (e) {
      return ""
    }
  }

  const getSortIcon = type => {
    const className = sortConfig.direction  === "des" ? "sort-up" : "sort-down"
    return type === sortConfig.type ? <i className={"fas fa-" + className}></i> : null
  }



    return (
      <div>
        <div className="table-responsive mt-2">
          <table className="table">
            <thead className="text-dark">
              <tr className="panel rounded">
                <th className="rounded-start"></th>
                <th></th>
                <Tippy content="typ"><th onClick={() => {requestSort("provided")}}>{getSortIcon("provided")}</th></Tippy>
                <Tippy content="opotrebiteľnosť"><th onClick={() => {requestSort("consumable")}}>{getSortIcon("consumable")}</th></Tippy>
                <Tippy content="externý odkaz"><th onClick={() => {requestSort("link")}}>{getSortIcon("link")}</th></Tippy>
                <Tippy content="výkres"><th onClick={() => {requestSort("drw")}}>{getSortIcon("drw")}</th></Tippy>
                <th onClick={() => {requestSort("name")}}>{getSortIcon("name")} Názov</th>  
                <th onClick={() => {requestSort("supplier")}}>{getSortIcon("supplier")} Dodávateľ</th>  
                <th onClick={() => {requestSort("num")}}>{getSortIcon("num")} Označenie</th>
                <th onClick={() => {requestSort("orderNum")}}>{getSortIcon("orderNum")} Objednávací kód</th>
                <th onClick={() => {requestSort("count")}}>{getSortIcon("count")} Počet kusov</th>                                                  
                <th className="rounded-end" style={{cursor: "default"}}>Umiestnenie</th>      
              </tr>
            </thead>
            <tbody className="text-light">
              {bom
              .sort(sortConfig.fn)
              .map((row, index) => {
                return(
                  <tr key={index}>
                    <td className="px-1">{index + 1}</td>
                    <TableRowImage row={row} resolveImageLink={resolveImageLink}/>
                    <td className="px-1">{resolveProvidedIcon(row)}</td>
                    <td className="px-1"><IconConsumable isShow={row.consumable} severity={row.params.klasifikacia}/></td>                                       
                    <td className="px-1"><a href={row.links[0]} target="_blank"><Icon isShow={row.links[0]} iconClassName="fas fa-link" tooltip={getOrigin(row.links[0])}/></a></td>
                    {/* <td className="px-1"><IconPDF onClick={() => {openPdf(row.pdfs[0])}} isShow={row.pdfs.length > 0} extraClass="pdf-icon"/></td>*/}
                    <TableRowDrawing row={row} openPdf={openPdf}/>
                    <TableRowName row={row} openDetail={openInfo} resolveCompNum={resolveCompNum} skipParams={skipParams} paramNameMap={paramNameMap}/>
                    <td>{row.params.dodavatel}</td>
                    <td>{resolveCompNum(row)}</td>
                    <td>{row.params.objednavaci_kod}</td>
                    <td className="px-0">{row.count}</td>                                                                              
                    <td className="px-1"><UsagePreview openInfo={openInfo} row={row} db={db}/></td>
                  </tr>)
              })}
            </tbody>
          </table>  
        </div>
      </div>
    );
}

export default Table;