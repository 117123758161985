import Tippy from "@tippyjs/react";

function TableRowImage (props) {

    const { row } = props
    const { resolveImageLink } = props

    return (
    <td>
        <Tippy theme="light" placement="right" maxWidth="10000px" content={<img className="preview-image rounded" src={resolveImageLink(row.images[0])}/>}>
            <img className="rounded table-image" src={resolveImageLink(row.images[0])} />
        </Tippy>              
    </td>  
    );
}

export default TableRowImage;