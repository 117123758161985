import Icon from "./Icon"

function IconConsumable(props) {

    const { isShow } = props
    const { severity } = props
    const { hideTooltip } = props

    const resolveSeverityColor = () => {
        switch(severity) {
            case "A": return "danger"
            case "B": return "warning"
            case "C": return "secondary"
            default: return "primary"
        }
    }

    const resolveSeverityText = () => {
        switch(severity) {
            case "A": return "veľmi "
            case "B": return ""
            case "C": return "mierne "
            default: return ""
        }
    }

    return (
        <Icon isShow={isShow} tooltip={hideTooltip ? undefined : resolveSeverityText() + "opotrebiteľný"} iconClassName={"fas fa-exclamation-triangle text-" + resolveSeverityColor()}/>
    );  
}

export default IconConsumable;