import logo from "../logo.png"

function About() {

    return (
    <div className="text-center">
        <img src={logo} alt="logo" className="w-100 p-2 rounded bg-light mt-1"/>
        © AV Systémy 2021
    </div>
    );
}

export default About;