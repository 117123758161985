import Icon from "./Icon"

function IconProvided(props) {

    const { isShow } = props
    const { hideTooltip } = props

    return (
        <Icon isShow={isShow} tooltip={hideTooltip ? undefined : "nakupovaný"} iconClassName="fas fa-shopping-basket text-info"/>
    );  
}

export default IconProvided;