import IconRoot from "../icon/IconRoot";
import Tippy from "@tippyjs/react";

function TableRowName(props) {

    const { row } = props
    const { openDetail } = props
    const { resolveCompNum } = props
    const { skipParams } = props
    const { paramNameMap } = props

    const formatVal = (key, val) => {
        let result
        switch(key) {
            case "hmotnost": 
                result = typeof val === "number" ? val.toFixed(3) : val
                break
            default: 
                result = val
                break
        }
        return typeof result === "string" ? result.trim() : result
    }

    const formatKey = key => {
        const name = paramNameMap.get(key)
        return name ? name : key.toUpperCase()
    }

    const formatName = name => {
        let result = "?"
        if(name) {
            if(typeof name === "string") {
                if(name.trim() !== "") result = name
            }
        }
        return result
    }

    return (
    <td>
        <Tippy theme="light" maxWidth="100%" content={
            <ul className="text-start detail-list m-4 no-text-shadow">
                <li><span className="fw-bold">Označenie: </span>{resolveCompNum(row)}</li>
                <li><span className="fw-bold">Počet kusov: </span>{row.count}</li>
                {Object.keys(row.params).map((key, index) => {
                    if(skipParams && !skipParams.includes(key)) {
                        const val = row.params[key]
                        const formatedVal = formatVal(key, val)
                        if(formatedVal === "") {
                            return null
                        } else {
                            const formatedKey = formatKey(key)
                            return <li key={index}><span className="fw-bold">{formatedKey}: </span>{formatedVal}</li>
                        }                                    
                    } else {
                        return null
                    }                                
                })}                                                                                
            </ul>
        }>
            <p className="text-info link" onClick={() => {openDetail(row)}}>
                {/* {row.root ? <span className="me-2 badge rounded-pill bg-primary">Hlavná </span>: null} */}
                {row.root ? <IconRoot /> : null}
                {formatName(row.params.nazov)}
            </p>   
        </Tippy>                     
    </td>  
    );
}

export default TableRowName;